<template>
  <div class="page" :class="{'ratio': devicePixelRatio === 2}">
    <div class="block bar">
      <div class="block_box">
        <div class="bar_content">
          <div class="title">ALL YOUR LOAN NEEDS IN ONE APP</div>
          <div class="gp">
            <a href="#" target="_blank"><img src="../assets/img/googleplay.png" height="37px"/></a>
          </div>
          <div class="hint">Quick, convenient & secure instant loan app. Cash transferred directly to your account. Enjoy flexible repayment options & tenures up to 12 months.without any hidden fees or commissions.</div>
        </div>
        <div class="bar_img"></div>
      </div>
    </div>
    <div class="block apply">
      <div class="block_box">
        <div class="apply_img1">
          <img src="../assets/img/loan.png">
        </div>
        <div class="apply_content">
          <div class="hint">Loan & Consumer Finance</div>
          <div class="title">Safe and efficient</div>
          <div class="hint">We provide market-competitive credit loan and consumer financial services in Nigeria, and meet people's needs with safe and efficient products and services.</div>
        </div>
      </div>
    </div>
    <div class="block1 prompt">
      <div class="block_box">
        <div class="title">Fraud alerts</div>
        <div class="line"></div>
        <div class="text">Fraudsters are using the internet, mail, and telephone in many ways to try to make you fall victim to their schemes. It’s important to remember that fraudsters are clever at gaining your trust and gathering your personal information. For the sake of safety, please do not click the link in the email or SMS of unknown origin, but dial the number in the email or SMS. You can choose to find the contact information of NiceCredit on our website or application to contact us.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      devicePixelRatio: window.devicePixelRatio,
    };
  },
  components: {

  },
};
</script>

<style lang="scss" scoped>
@mixin pixel-ratio() {}

.page {
  @include pixel-ratio();
  text-align: center;
  .bar {
    .bar_img {
     // background-image: url('~@/assets/img/banner.png');
    }
  }
  .apply {
    .apply_img {
      background-image: url('~@/assets/img/apply.png');
    }
  }
  .over {
    .over_img {
      background-image: url('~@/assets/img/over.png');
    }
  }
  .ratio {
    .bar {
      .bar_img {
        // background-image: url('~@/assets/img/banner@2x.png');
      }
    }
    .apply {
      /deep/ .el-carousel__item {
        &:nth-child(1) {
          background-image: url('~@/assets/img/loan.png');
        }
        &:nth-child(2) {
          background-image: url('~@/assets/img/work.png');
        }
        &:nth-child(3) {
          background-image: url('~@/assets/img/self.png');
        }
      }
    }
    .over {
      .over_img {
        background-image: url('~@/assets/img/over@2x.png');
      }
    }
  }
  .apply {
    /deep/ .el-carousel__container {
      .el-carousel__item {
        background-size: cover;
        background-position: 100%;
        &:nth-child(1) {
          background-image: url('~@/assets/img/loan.png');
        }
        &:nth-child(2) {
          background-image: url('~@/assets/img/work.png');
        }
        &:nth-child(3) {
          background-image: url('~@/assets/img/self.png');
        }
        img {
          height: 100%;
        }
      }
    }
  }

  /deep/ .el-carousel__indicators {
    .el-carousel__indicator {
      .el-carousel__button {
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background-color: white;
      }
    }
    .is-active .el-carousel__button {
      background-color: #333333;
    }
  }
  .block {
    text-align: left;
    .block_box {
      display: flex;
    }
    .title {
      font-size: 32px;
      font-weight: 700;
      color: #333333;
    }
    .text {
      font-size: 14px;
      color: #333333;
      padding: 10px 0px;
    }
    .hint {
     font-size: 13px;
     color: #999999;
     padding: 10px 0px;
    }
    .el-button {
      margin-top: 30px;
    }
    &_box {
      max-width: 1200px;
      margin: 0 auto;
    }
    &.bar {
      background: #FBF9F9;
      .block_box {
        position: relative;
      }
      .bar_img {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
      }
      .bar_content {
        position: relative;
        z-index: 1;
        padding: 10px;
        flex: 1;
        padding-top: 100px;
        padding-bottom: 40px;
        max-width: 600px;
        .gp{
          margin-top: 20px;
        }
        .licensed {
          font-size: 14px;
          color: #999999;
          margin-top: 10px;
          &_logo {
            background: url("../assets/img/fccpc.png") no-repeat;
            background-size: auto 30px;
            width: 50px;
            height: 30px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
          }
        }
      }
    }
    &.apply {
      background: white;
      margin-top: 64px;
      .apply_content {
        padding: 10px;
        background-color: #F5F5F5;
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 55px;
        // flex: 1;
      }
      .apply_img {
        min-width: 310px;
        background-size: 100%;
        background-repeat: no-repeat;
      }
      .apply_img1{
        min-width: 310px;
        background-size: 100%;
        background-repeat: no-repeat;
        > img{
          width: 300px;
        }
      }
    }
    &.prompt {
      margin-top: 40px;
      .block_box {
        padding: 10px;
      }
    }
    &.over {
      margin-top: 64px;
      .over_content {
        padding: 10px;
        padding-top: 55px;
        flex: 1;
      }
      .over_img {
        width: 295px;
        height: 295px;
        background-size: 100%;
        background-repeat: no-repeat;
        text-align: center;
        position: relative;
        >div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
          font-size: 21px;
          span {
            font-size: 14px;
            color: #F0ECF5;
          }
          div {
            font-size: 47px;
            font-weight: bold;
          }
        }
      }
    }
    &.ready {
      margin-top: 64px;
      margin-bottom: 64px;
      .ready_content {
        padding: 10px;
        padding-top: 55px;
        flex: 1;
        text-align: right;
      }
      .ready_img {
        max-width: 396px;
        img {
          width: 100%;
        }
      }
    }
  }

  .block1 {
    text-align: left;
    .title {
      font-size: 32px;
      font-weight: 700;
      color: #333333;
    }
    .text {
      font-size: 14px;
      color: #333333;
      padding: 10px 0px;
    }
    .hint {
      font-size: 13px;
      color: #333333;
      padding: 10px 0px;
    }
    .line {
      height: 0.5px;
      background: #CCCCCC;
    }
    .el-button {
      margin-top: 30px;
    }
    &_box {
      max-width: 1200px;
      margin: 0 auto;
    }
    &.bar {
      position: relative;
      background: white;
      .bar_img {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .bar_content {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .bar_value {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          padding: 0px 10px;
          max-width: 1200px;
          margin: 0 auto;
          .hint {
            max-width: 500px;
          }
        }
      }
    }
    &.prompt {
      margin-top: 40px;
      .block_box {
        padding: 10px;
      }
    }
    &.list {
      margin-top: 40px;
      .block_box {
        padding: 10px;
      }
      .list_content {
        margin-top: 30px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        &_item {
          background: rgba(103, 45, 158, 0.03);
          border-radius: 4px;
          display: inline-block;
          margin-bottom: 16px;
          width: 30%;
          .img {
            width: 35px !important;
            height: 35px !important;
            border-radius: 35px;
            background: rgba(103, 45, 158, 0.1);
            margin-top: 35px;
            margin: 35px 20px 10px 20px;
          }
          .text {
            padding: 0px 20px;
          }
          .hint {
            margin-top: 15px;
            margin-bottom: 30px;
            color: #333333;
            padding: 0px 20px;
          }
        }
      }
    }
    &.join {
      margin-top: 33px;
      margin-bottom: 20px;
      .block_box {
        display: flex;
      }
      .join_content {
        padding: 10px;
        padding-top: 55px;
        margin-bottom: 20px;
        flex: 1;
      }
      .join_img {
        max-width: 396px;
        font-size: 0px;
        img {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .block {
      text-align: center;
      .block_box {
        display: inline-block;
      }
      &.bar {
        .bar_img {
          background-size: cover;
          top: 140px;
          bottom: 120px;
        }
        .title {
          font-size: 26px;
        }
        .el-button {
          margin-top: 330px;
        }
        .bar_content {
          padding-top: 30px;
          padding-bottom: 20px;
        }
      }
      &.apply {
        .apply_content {
          background: white;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      &.over {
        .over_img {
          margin: 0 auto;
        }
        .over_content {
          float: left;
          margin-top: 290px;
        }
      }
      &.ready {
        .ready_img {
          margin: 0 auto;
        }
        .ready_content {
          padding-top: 30px;
          text-align: center;
        }
      }
    }
  }
}
</style>
